import React from "react";
import { Link } from "gatsby";
import "./CardService.scss";

const CardService = (props) => {
  return (
    <Link to={props.href} className="card-service">
      <h4 className="card-service-heading">{props.text}</h4>
      <div className="card-service-img">
        <img src={props.image} alt="service" />
      </div>
      <div className="card-service-lead">
        <p className="card-service-lead-text">{props.lead}</p>
        <p className="card-service-lead-link">サービス詳細を見る</p>
      </div>
    </Link>
  );
};

export default CardService;
