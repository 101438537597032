import React from "react";
import "./CardStrong.scss";
import ButtonPrimary from "./ButtonPrimary";

const CardStrong = (props) => {
  return (
    <div className="card-strong">
      <div className="card-strong-img">
        <img src={props.img} alt="strong" />
      </div>
      <div className="card-strong-lead">
        <h4 className="card-strong-lead-heading">{props.title}</h4>
        <p className="card-strong-lead-text">{props.lead}</p>
        <ButtonPrimary text={props.btntext} href={props.btnhref} />
      </div>
    </div>
  );
};

export default CardStrong;
