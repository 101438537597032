import React from "react";
import { Link } from "gatsby";
import "./ButtonPrimary.scss";

const ButtonPrimary = (props) => {
  return (
    <Link className="btn-primary" to={props.href}>
      {props.text}
    </Link>
  );
};

export default ButtonPrimary;
