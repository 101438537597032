import React from "react";
import { Link } from "gatsby";
import "./ButtonTertialy.scss";

const ButtonTertialy = (props) => {
  return (
    <Link className="btn-tertialy" to={props.href}>
      <span className="material-icons">{props.icon}</span>
      <span>{props.text}</span>
    </Link>
  );
};

export default ButtonTertialy;
