import React from "react";
import Layout from "../components/Layout";
import ButtonPrimary from "../atoms/ButtonPrimary";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ButtonTertialy from "../atoms/ButtonTertialy";
import CardService from "../atoms/CardService";
import CardStrong from "../atoms/CardStrong";
import "./index.scss";
import Helmet from "react-helmet";
import Icon from "./../images/favicon.ico";
import Service01 from "../images/index-service-01.jpg";
import Service02 from "../images/index-service-02.jpg";
import Service03 from "../images/index-service-03.jpg";
import Strong01 from "../images/index-strong-01.jpg";
import Strong02 from "../images/index-strong-02.jpg";
import Voice01 from "../images/index-voice-01.jpg";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>MINT</title>
        <html lang="ja" />
        <meta name="description" content="このページはモックサイトです。" />
        <link rel="icon" type="image/png" href={Icon} />
      </Helmet>
      <section className="fv-section">
        <div className="container">
          <div className="fv-inner">
            <h1 className="fv-inner-text">
              XXXの課題を解決する製品を
              <br />
              取り扱っています！
            </h1>
            <h4 className="fv-inner-lead">こんなことがあればご相談ください</h4>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <h2 className="section-heading">私たちはこんな会社です。</h2>
          <div className="section-inner">
            <h4 className="section-inner-lead">
              私たちはXXXXです。
              <br />
              サンプルテキストやサンプルテキストでお困りの方や
              <br />
              サンプルテキストがしたい方へ向けた製品の製造・販売をしています。
            </h4>
          </div>
          <div className="section-footer">
            <ButtonPrimary text="会社概要を見る" href="/" />
          </div>
        </div>
      </section>
      <section className="service-section">
        <div className="container">
          <h2 className="section-heading">弊社の行なっているサービス</h2>
          <div className="section-inner">
            <CardService
              text="製品開発・販売"
              image={Service01}
              href="/"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
            <CardService
              text="受託開発"
              image={Service02}
              href="/"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
            <CardService
              text="納品後サポート"
              image={Service03}
              href="/"
              lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
            />
          </div>
          <div className="section-footing">
            <h4>
              他にもいろいろなサービスを行なっております。
              <br />
              サービス一覧からご確認ください。
            </h4>
            <ButtonPrimary href="/" text="サービス一覧を見る" />
          </div>
        </div>
      </section>
      <section className="strong-section">
        <div className="container">
          <h2 className="section-heading">弊社製品の特徴</h2>
          <div className="section-inner">
            <h4 className="section-inner-lead">
              競合と比べてこんな特徴があります。
              <br />
              サンプルやサンプルといったオプションも可能です。
            </h4>
            <div className="section-inner-card">
              <CardStrong
                title="XX技術"
                img={Strong01}
                lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
                btntext="詳細を見る"
                btnhref="/"
              />
              <CardStrong
                title="XXX製法"
                img={Strong02}
                lead="サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト"
                btntext="詳細を見る"
                btnhref="/"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="voice-section">
        <div className="container">
          <div className="section-inner">
            <div className="section-inner-img">
              <img src={Voice01} alt="test" />
            </div>
            <div className="section-inner-txt">
              <div className="section-inner-txt-heading">
                <h3 className="section-inner-txt-heading-title">お客様の声</h3>
                <p className="section-inner-txt-heading-lead">
                  サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
                </p>
              </div>
              <div className="section-inner-txt-footing">
                <ButtonPrimary text="お客様の声を見る" href="/" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="recruit-section newly">
        <div className="container">
          <div className="section-inner">
            <div className="section-inner-heading">
              <h3 className="section-inner-heading-text">新卒採用</h3>
              <p>
                サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </div>
            <div className="section-inner-footing">
              <ButtonSecondary text="新卒採用情報はこちら" href="/" />
            </div>
          </div>
        </div>
      </section>
      <section className="recruit-section midway">
        <div className="container">
          <div className="section-inner">
            <div className="section-inner-heading">
              <h3 className="section-inner-heading-text">中途採用</h3>
              <p>
                サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </div>
            <div className="section-inner-footing">
              <ButtonSecondary text="中途採用情報はこちら" href="/" />
            </div>
          </div>
        </div>
      </section>
      <section className="info-section">
        <div className="container">
          <h3 className="section-heading">資料請求・お問い合わせはこちら</h3>
          <div className="section-inner">
            <div className="section-inner-btn">
              <ButtonTertialy
                text="資料をダウンロード"
                href="/"
                icon="download_for_offline"
              />
            </div>
            <div className="section-inner-btn">
              <ButtonTertialy text="お問い合わせ" href="/" icon="send" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
