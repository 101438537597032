import React from "react";
import { Link } from "gatsby";
import "./Logo.scss";
import Icon from "../images/icon.png";

const Logo = () => {
  return (
    <Link to="/" className="logo">
      <img src={Icon} alt="logo" />
      DEMANDLINK
    </Link>
  );
};

export default Logo;
