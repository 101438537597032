import React from "react";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Logo from "../atoms/Logo";
import "./Header.scss";
import NavToggle from "./NavToggle";

const Header = () => {
  const navToggle = () => {
    const target = document.getElementById("navToggle");
    target.classList.toggle("nav-toggle-open");
  };
  return (
    <header>
      <div className="container">
        <Logo />
        <div className="info">
          <span className="info-access">
            東京都XXX区XX-XX-XX
            <br />
            TEL：XX-XXXX-XXXX
          </span>
          <div className="info-btn">
            <ButtonPrimary href="/" text="資料請求" />
          </div>
          <div className="info-btn">
            <ButtonPrimary href="/" text="お問い合わせ" />
          </div>
        </div>
        <div className="nav-toggle-icon">
          <button className="material-icons" onClick={navToggle}>
            menu
          </button>
        </div>
        <NavToggle />
      </div>
    </header>
  );
};

export default Header;
