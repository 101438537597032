import React from "react";
import { Link } from "gatsby";
import "./Navigation.scss";

const Navigation = () => {
  return (
    <nav className="global-nav">
      <div className="container">
        <Link to="/" className="nav-link" activeClassName="nav-link-active">
          ホーム
        </Link>
        <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
          私たちについて
          <div className="subnav">
            <Link to="/sub">サブメニュー</Link>
            <Link to="/sub">サブメニュー</Link>
            <Link to="/sub">サブメニュー</Link>
          </div>
        </Link>
        <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
          サービス
          <div className="subnav">
            <Link to="/sub">サブメニュー</Link>
            <Link to="/sub">サブメニュー</Link>
            <Link to="/sub">サブメニュー</Link>
          </div>
        </Link>
        <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
          お客様の声
        </Link>
        <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
          採用情報
        </Link>
      </div>
    </nav>
  );
};

export default Navigation;
