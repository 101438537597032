import React from "react";
import "./Footer.scss";
import Logo from "../atoms/Logo";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <Logo />
        <span>東京都XXX区XX-XX-XX</span>
        <span>TEL：XX-XXXX-XXXX</span>
        <span>MAIL：XXXXX@XXX.XX</span>
      </div>
    </footer>
  );
};

export default Footer;
