import React from "react";
import { Link } from "gatsby";
import "./NavToggle.scss";

const NavToggle = () => {
  return (
    <nav className="nav-toggle" id="navToggle">
      <Link to="/" className="nav-link" activeClassName="nav-link-active">
        ホーム
      </Link>
      <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
        私たちについて
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
        サービス
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link className="subnav" to="/sub">
        サブメニュー
      </Link>
      <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
        お客様の声
      </Link>
      <Link to="/sub" className="nav-link" activeClassName="nav-link-active">
        採用情報
      </Link>
      <div className="nav-toggle-info">
        <p>東京都XXX区XX-XX-XX</p>
        <p>TEL：XX-XXXX-XXXX</p>
        <p>MAIL：XXXXX@XXX.XX</p>
      </div>
    </nav>
  );
};

export default NavToggle;
