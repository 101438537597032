import React from "react";
import { Link } from "gatsby";
import "./ButtonSecondary.scss";

const ButtonSecondary = (props) => {
  return (
    <Link className="btn-secondary" to={props.href}>
      {props.text}
    </Link>
  );
};

export default ButtonSecondary;
